import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { setHomeEquity, thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import HomeEquityComp from "../../../../components/ContentRow/Step3/Properties/HomeEquityComp";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const HomeEquity = ( props ) => {

    const [home, setHome] = useState( props.properties.home.length || 1 );
    const titles = {
        layoutTitle: 'YOUR SAVINGS & INVESTMENTS',
        sectionTitle: 'What is the equity in your home?',
    };

    const title = "Retirement - How much equity do you have in your home?";

    const addHome = () => {
        setHome(home+1)
    };

    const onClose = () => {
        setHome(home-1);

        //reset cottage 2 val
        const homeValues = props.properties.home;
        homeValues.splice(1,1);
        props.setHomeEquity(homeValues)
    };

    const [next, setNext] = useState(retirementCalcLinks.inheritance);

    const nextLink = useCallback(() => {
        if(props.properties.isCottage){
            return retirementCalcLinks.cottageEquity;
        }else if(props.properties.isRentalProperties) {
            return retirementCalcLinks.rentalEquity;
        }else {
            return retirementCalcLinks.inheritance;
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(14/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='cottageEquity'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'family'}>
                    <HomeEquityComp  num={1}/>
                </InnerLayoutStepContent>

                {
                    home === 2 &&
                    <InnerLayoutStepContent style={{position: 'relative'}}>

                        <span className='sva-delete-property'>
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={onClose}
                            />
                        </span>
                        <HomeEquityComp num={2} />

                    </InnerLayoutStepContent>
                }

                {
                    home < 2 &&
                    <div className='sva-add-another'>
                        <span onClick={addHome}>+</span>
                        Add another home
                    </div>
                }


            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        properties: state.savingsAndInvestments.properties,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHomeEquity : val => dispatch(setHomeEquity(val)),
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeEquity);