import React, { useState } from 'react';
import Total from "../Total/Total";
import { connect } from "react-redux";
import { setHomeEquity } from "../../../../store/actions/savingsAndInvestments";

const HomeEquityComp = ( props ) => {
    const [home, setHome] = useState(props.home);

    function startValue() {
        let val = props.home[0]
        if (props.num === 2 ) {
            val = props.home[1]
        }

        return val || 0
    }

    const onChange = val => {
        let homeUpdated = [...home];
        if (props.num === 1 ) {
            homeUpdated[0] = val;
        }else {
            homeUpdated[1] = val;
        }
        setHome(homeUpdated)
        props.setHomeEquity(homeUpdated)
    };

    return (
        <React.Fragment>
            <div className='sva-property-container'>
                <h3 >Home {props.num}</h3>
                < Total
                    type={'properties'}
                    total={startValue()}
                    title={'Total equity'}
                    onChange={onChange}
                />
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        home: state.savingsAndInvestments.properties.home
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setHomeEquity : val => dispatch(setHomeEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeEquityComp);